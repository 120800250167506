import types                from './constants';
import initialState         from './initialState';
import * as commonReducer   from './commonReducer';

const INITIAL_STATE = ({ ...initialState });

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.MODALFIELDS_SET_DATA_TYPE                            : return commonReducer.SET_DATA_TYPE(state, action);
    case types.MODALFIELDS_SET_SWIPEABLE                            : return commonReducer.SET_SWIPEABLE(state, action);
    case types.MODALFIELDS_SET_TAB_FIELD                            : return commonReducer.SET_TAB_FIELD(state, action);
    case types.MODALFIELDS_RESET                                    : return commonReducer.RESET(state, action);
    case types.MODALFIELDS_SET_ENABLE_FIELD                         : return commonReducer.SET_ENABLE_FIELD(state, action);
    case types.MODALFIELDS_SET_ENABLE_NEW_LINE                      : return commonReducer.SET_ENABLE_NEW_LINE(state, action);
    case types.MODALFIELDS_SET_ENABLE_NEW_PAGE                      : return commonReducer.SET_ENABLE_NEW_PAGE(state, action);
    case types.MODALFIELDS_SET_SIZE                                 : return commonReducer.SET_SIZE(state, action);
    case types.MODALFIELDS_SET_ENABLE_PERIOD_INTERVAL               : return commonReducer.SET_ENABLE_PERIOD_INTERVAL(state, action);
    case types.MODALFIELDS_SET_ENABLE_OBSERVATION                   : return commonReducer.SET_ENABLE_OBSERVATION(state, action);
    case types.MODALFIELDS_SET_RANGE_MIN                            : return commonReducer.SET_RANGE_MIN(state, action);
    case types.MODALFIELDS_SET_RANGE_MAX                            : return commonReducer.SET_RANGE_MAX(state, action);
    case types.MODALFIELDS_SET_INFO_RANGE_MIN                       : return commonReducer.SET_INFO_RANGE_MIN(state, action);
    case types.MODALFIELDS_SET_INFO_RANGE_MAX                       : return commonReducer.SET_INFO_RANGE_MAX(state, action);
    case types.MODALFIELDS_SET_SCORE_RANGE_MIN                      : return commonReducer.SET_SCORE_RANGE_MIN(state, action);
    case types.MODALFIELDS_SET_SCORE_RANGE_MAX                      : return commonReducer.SET_SCORE_RANGE_MAX(state, action);
    case types.MODALFIELDS_SET_ENABLE_ORIENTATION                   : return commonReducer.SET_ENABLE_ORIENTATION(state, action);
    case types.MODALFIELDS_SET_ENABLE_SELECT_LIST_DESIGN            : return commonReducer.SET_ENABLE_SELECT_LIST_DESIGN(state, action);
    case types.MODALFIELDS_SET_FIELD_TITLE                          : return commonReducer.SET_FIELD_TITLE(state, action);
    case types.MODALFIELDS_SET_FIELD_DISPLAY_TITLE                  : return commonReducer.SET_FIELD_DISPLAY_TITLE(state, action);
    case types.MODALFIELDS_SET_FIELD_MEASUREMENT_UNIT               : return commonReducer.SET_FIELD_MEASUREMENT_UNIT(state, action);
    case types.MODALFIELDS_SET_FIELD_POINTS                         : return commonReducer.SET_FIELD_POINTS(state, action);
    case types.MODALFIELDS_SET_CHECKPOINTS                          : return commonReducer.SET_FIELD_CHECKPOINTS(state, action);
    case types.MODALFIELDS_SET_EMPTY_POINTS_WARNING                 : return commonReducer.SET_FIELD_EMPTY_POINTS_WARNING(state, action);
    case types.MODALFIELDS_SET_FIELD_OPTIONS                        : return commonReducer.SET_FIELD_OPTIONS(state, action);
    case types.MODALFIELDS_SET_OPTIONS                              : return commonReducer.SET_OPTIONS(state, action);
    case types.MODALFIELDS_SET_FIELD_OPTION_BY_ID                   : return commonReducer.SET_FIELD_OPTION_BY_ID(state, action);
    case types.MODALFIELDS_SET_FIELD_OPTIONS_UPDATE                 : return commonReducer.SET_FIELD_OPTIONS_UPDATE(state, action);
    case types.MODALFIELDS_SET_SEARCH_EXAM_RESET                    : return commonReducer.SET_SEARCH_EXAM_RESET(state, action);
    case types.MODALFIELDS_SEARCH_EXAM_FETCH_REQUEST                : return commonReducer.SEARCH_EXAM_FETCH_REQUEST(state, action);
    case types.MODALFIELDS_SEARCH_EXAM_FETCH_SUCCESS                : return commonReducer.SEARCH_EXAM_FETCH_SUCCESS(state, action);
    case types.MODALFIELDS_SEARCH_EXAM_FETCH_ERROR                  : return commonReducer.SEARCH_EXAM_FETCH_ERROR(state, action);
    case types.MODALFIELDS_SET_DEFAULT_EXAMS                        : return commonReducer.SET_DEFAULT_EXAMS(state, action);
    case types.MODALFIELDS_SET_CONTEXT                              : return commonReducer.SET_CONTEXT(state, action);
    case types.MODALFIELDS_SAVE_FETCH_REQUEST                       : return commonReducer.SAVE_FETCH_REQUEST(state, action);
    case types.MODALFIELDS_SAVE_FETCH_SUCCESS                       : return commonReducer.SAVE_FETCH_SUCCESS(state, action);
    case types.MODALFIELDS_SAVE_FETCH_ERROR                         : return commonReducer.SAVE_FETCH_ERROR(state, action);
    case types.MODALFIELDS_SET_FIELD_BY_ID                          : return commonReducer.SET_FIELD_BY_ID(state, action);
    case types.MODALFIELDS_SET_FIELD_DESCRIPTION                    : return commonReducer.SET_FIELD_DESCRIPTION(state, action);
    case types.MODALFIELDS_SET_GET_FIELD_BY_ID_LOADING              : return commonReducer.SET_GET_FIELD_BY_ID_LOADING(state, action);
    case types.MODALFIELDS_UPDATE_FIELD_MODEL_BY_ID_FETCH_REQUEST   : return commonReducer.UPDATE_FIELD_MODEL_BY_ID_FETCH_REQUEST(state, action);
    case types.MODALFIELDS_UPDATE_FIELD_MODEL_BY_ID_FETCH_SUCCESS   : return commonReducer.UPDATE_FIELD_MODEL_BY_ID_FETCH_SUCCESS(state, action);
    case types.MODALFIELDS_UPDATE_FIELD_MODEL_BY_ID_FETCH_ERROR     : return commonReducer.UPDATE_FIELD_MODEL_BY_ID_FETCH_ERROR(state, action);
    case types.MODALFIELDS_SET_ENABLE_CONFIG_SCORE                  : return commonReducer.SET_ENABLE_CONFIG_SCORE(state, action);
    case types.MODALFIELDS_SET_SELECT_QUALIFIER                     : return commonReducer.SET_SELECT_QUALIFIER(state, action);
    case types.MODALFIELDS_SET_SELECT_QUALIFIER_DEFICIENCY          : return commonReducer.SET_SELECT_QUALIFIER_DEFICIENCY(state, action);
    case types.MODALFIELDS_SET_SELECT_QUALIFIER_ID                  : return commonReducer.SET_SELECT_QUALIFIER_ID(state, action);
    case types.MODALFIELDS_SET_QUALIFIER_DEFICIENCY_ID              : return commonReducer.SET_QUALIFIER_DEFICIENCY_ID(state, action);
    case types.MODALFIELDS_SET_REFERENCE_FIELD                      : return commonReducer.SET_REFERENCE_FIELD(state, action);
    case types.MODALFIELDS_SET_SELECT_ANALYZE_BETTER                : return commonReducer.SET_SELECT_ANALYZE_BETTER(state, action);
    case types.MODALFIELDS_SET_SELECT_TYPE_SCORE                    : return commonReducer.SET_SELECT_TYPE_SCORE(state, action);
    case types.MODALFIELDS_SET_DEFICIENCY_CALC                      : return commonReducer.SET_DEFICIENCY_CALC(state, action);
    case types.MODALFIELDS_SET_TYPE_REFERENCE                       : return commonReducer.SET_TYPE_REFERENCE(state, action);
    case types.MODALFIELDS_SET_LIST_QUALIFIERS_FETCH_SUCCESS        : return commonReducer.SET_LIST_QUALIFIERS_FETCH_SUCCESS(state, action);

    default: return state;
  }
};