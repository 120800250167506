//DEPENDENDO DO CONTEXTO PODE VARIAR UM POUCO OS OBJETOS
export default ({
  context: '',
  formOrigin: null,
  tabDesignerDisabled: true,
  viewOnly: false,
  defaultExams: [],
  fetching: {
    saveField: false,
    examSearch: false,
    editField: false,
    updateFieldModelById: false,
    getFieldById: false
  },
  qualifiers: [],
  field: {
    uuid: '',
    name: '',
    title: '',
    description: '',
    size: 12,
    dataType: 0,
    radioGroupModelId: 0,
    checkboxGroupModelId: 0,
    rangeMin: 0,
    rangeMax: 10,
    infoMin: '',
    infoMax: '',
    scoreMin: 0,
    scoreMax: 100,
    deficiencyCalc: false,
    typeReference: 'minMax',
    enableNewLine: false,
    enableNewPage: false,
    enableInline: false,
    enableSelectListDesign: false,
    enableObservation: false,
    enablePeriodInterval: false,
    examsIds: [],
    optionsIds: [0],
    originalDataType: 0,
    points: null,
    checkPoints: null,
    emptyPointsWarning: false,
    measurementUnit: null,
    displayName: null,

    //se existir o style passa a ser do modelo de Formulário
    fieldModelId: 0,

    //na hora de criar o modelo de Formulário
    style: {}
  },
  optionsIndexed : {
    0: { id: 0, value: '', points:null }
  },
  examsIndexed: {},
  swipe: {
    index: '0'
  },
  api: {
    examSearch: []
  }
});