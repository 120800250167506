export default ({
  MODALFIELDS_SET_DATA_TYPE                           : 'MODALFIELDS_SET_DATA_TYPE',
  MODALFIELDS_SET_SWIPEABLE                           : 'MODALFIELDS_SET_SWIPEABLE',
  MODALFIELDS_SET_TAB_FIELD                           : 'MODALFIELDS_SET_TAB_FIELD',
  MODALFIELDS_RESET                                   : 'MODALFIELDS_RESET',
  MODALFIELDS_SET_ENABLE_FIELD                        : 'MODALFIELDS_SET_ENABLE_FIELD',
  MODALFIELDS_SET_ENABLE_NEW_LINE                     : 'MODALFIELDS_SET_ENABLE_NEW_LINE',
  MODALFIELDS_SET_ENABLE_NEW_PAGE                     : 'MODALFIELDS_SET_ENABLE_NEW_PAGE',
  MODALFIELDS_SET_SIZE                                : 'MODALFIELDS_SET_SIZE',
  MODALFIELDS_SET_ENABLE_PERIOD_INTERVAL              : 'MODALFIELDS_SET_ENABLE_PERIOD_INTERVAL',
  MODALFIELDS_SET_ENABLE_OBSERVATION                  : 'MODALFIELDS_SET_ENABLE_OBSERVATION',
  MODALFIELDS_SET_RANGE_MIN                           : 'MODALFIELDS_SET_RANGE_MIN',
  MODALFIELDS_SET_RANGE_MAX                           : 'MODALFIELDS_SET_RANGE_MAX',
  MODALFIELDS_SET_ENABLE_ORIENTATION                  : 'MODALFIELDS_SET_ENABLE_ORIENTATION',
  MODALFIELDS_SET_ENABLE_SELECT_LIST_DESIGN           : 'MODALFIELDS_SET_ENABLE_SELECT_LIST_DESIGN',
  MODALFIELDS_SET_FIELD_TITLE                         : 'MODALFIELDS_SET_FIELD_TITLE',
  MODALFIELDS_SET_FIELD_DISPLAY_TITLE                 : 'MODALFIELDS_SET_FIELD_DISPLAY_TITLE',
  MODALFIELDS_SET_FIELD_MEASUREMENT_UNIT              : 'MODALFIELDS_SET_FIELD_MEASUREMENT_UNIT',
  MODALFIELDS_SET_FIELD_POINTS                        : 'MODALFIELDS_SET_FIELD_POINTS',
  MODALFIELDS_SET_CHECKPOINTS                         : 'MODALFIELDS_SET_CHECKPOINTS',
  MODALFIELDS_SET_EMPTY_POINTS_WARNING                : 'MODALFIELDS_SET_EMPTY_POINTS_WARNING',
  MODALFIELDS_SET_OPTIONS                             : 'MODALFIELDS_SET_OPTIONS',
  MODALFIELDS_SET_FIELD_OPTIONS                       : 'MODALFIELDS_SET_FIELD_OPTIONS',
  MODALFIELDS_SET_FIELD_OPTION_BY_ID                  : 'MODALFIELDS_SET_FIELD_OPTION_BY_ID',
  MODALFIELDS_SET_FIELD_OPTIONS_UPDATE                : 'MODALFIELDS_SET_FIELD_OPTIONS_UPDATE',
  MODALFIELDS_SET_SEARCH_EXAM_RESET                   : 'MODALFIELDS_SET_SEARCH_EXAM_RESET',
  MODALFIELDS_SEARCH_EXAM_FETCH_REQUEST               : 'MODALFIELDS_SEARCH_EXAM_FETCH_REQUEST',
  MODALFIELDS_SEARCH_EXAM_FETCH_SUCCESS               : 'MODALFIELDS_SEARCH_EXAM_FETCH_SUCCESS',
  MODALFIELDS_SEARCH_EXAM_FETCH_ERROR                 : 'MODALFIELDS_SEARCH_EXAM_FETCH_ERROR',
  MODALFIELDS_SET_DEFAULT_EXAMS                       : 'MODALFIELDS_SET_DEFAULT_EXAMS',
  MODALFIELDS_SET_CONTEXT                             : 'MODALFIELDS_SET_CONTEXT',
  MODALFIELDS_SAVE_FETCH_REQUEST                      : 'MODALFIELDS_SAVE_FETCH_REQUEST',
  MODALFIELDS_SAVE_FETCH_SUCCESS                      : 'MODALFIELDS_SAVE_FETCH_SUCCESS',
  MODALFIELDS_SAVE_FETCH_ERROR                        : 'MODALFIELDS_SAVE_FETCH_ERROR',
  MODALFIELDS_SET_FIELD_BY_ID                         : 'MODALFIELDS_SET_FIELD_BY_ID',
  MODALFIELDS_SET_FIELD_DESCRIPTION                   : 'MODALFIELDS_SET_FIELD_DESCRIPTION',
  MODALFIELDS_UPDATE_FIELD_MODEL_BY_ID_FETCH_REQUEST  : 'MODALFIELDS_UPDATE_FIELD_MODEL_BY_ID_FETCH_REQUEST',
  MODALFIELDS_UPDATE_FIELD_MODEL_BY_ID_FETCH_SUCCESS  : 'MODALFIELDS_UPDATE_FIELD_MODEL_BY_ID_FETCH_SUCCESS',
  MODALFIELDS_UPDATE_FIELD_MODEL_BY_ID_FETCH_ERROR    : 'MODALFIELDS_UPDATE_FIELD_MODEL_BY_ID_FETCH_ERROR',
  MODALFIELDS_SET_GET_FIELD_BY_ID_LOADING             : 'MODALFIELDS_SET_GET_FIELD_BY_ID_LOADING',
  MODALFIELDS_SET_INFO_RANGE_MIN                      : 'MODALFIELDS_SET_INFO_RANGE_MIN',
  MODALFIELDS_SET_INFO_RANGE_MAX                      : 'MODALFIELDS_SET_INFO_RANGE_MAX', 

  MODALFIELDS_SET_SCORE_RANGE_MAX                     : 'MODALFIELDS_SET_SCORE_RANGE_MAX',
  MODALFIELDS_SET_SCORE_RANGE_MIN                     : 'MODALFIELDS_SET_SCORE_RANGE_MIN',
  MODALFIELDS_SET_SELECT_QUALIFIER                    : 'MODALFIELDS_SET_SELECT_QUALIFIER',
  MODALFIELDS_SET_SELECT_QUALIFIER_ID                 : 'MODALFIELDS_SET_SELECT_QUALIFIER_ID',
  MODALFIELDS_SET_QUALIFIER_DEFICIENCY_ID             : 'MODALFIELDS_SET_QUALIFIER_DEFICIENCY_ID',
  MODALFIELDS_SET_REFERENCE_FIELD                     : 'MODALFIELDS_SET_REFERENCE_FIELD',
  MODALFIELDS_SET_SELECT_ANALYZE_BETTER               : 'MODALFIELDS_SET_SELECT_ANALYZE_BETTER',
  MODALFIELDS_SET_SELECT_TYPE_SCORE                   : 'MODALFIELDS_SET_SELECT_TYPE_SCORE',
  MODALFIELDS_SET_DEFICIENCY_CALC                     : 'MODALFIELDS_SET_DEFICIENCY_CALC',
  MODALFIELDS_SET_TYPE_REFERENCE                      : 'MODALFIELDS_SET_TYPE_REFERENCE',
  MODALFIELDS_SET_ENABLE_CONFIG_SCORE                 : 'MODALFIELDS_SET_ENABLE_CONFIG_SCORE',
  MODALFIELDS_SET_LIST_QUALIFIERS_FETCH_SUCCESS       : 'MODALFIELDS_SET_LIST_QUALIFIERS_FETCH_SUCCESS',
  MODALFIELDS_SET_SELECT_QUALIFIER_DEFICIENCY         : 'MODALFIELDS_SET_SELECT_QUALIFIER_DEFICIENCY',
});