import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';
import axiosRef from 'axios';
import { get } from '../util';
import { post } from '../util';
import { put } from '../util';
import { patch } from '../util';
import { del } from '../util';

const ANSWERS = 'answers';
const FORM = 'form';
const MODEL = 'model';
const VERSION = 'version';
const EMPTY = 'empty';
const DRAFT = 'draft';
const NAME = 'name';
const AVAILABILITY = 'availability';
const ACCOUNT = 'account';
const PRINT_FORMAT = 'printFormat';
const GLOBALS = 'globals';
const PATIENT = 'patient';
const ANSWER = 'answer';
const ATTENDANCE = 'attendance';

//#region GET
export const getFormModels = async ({ header, isAccountForm = 0 }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${MODEL}?isAccountForm=${isAccountForm}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFormGlobals = async ({ header }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${MODEL}/${GLOBALS}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFormAnswers = async ({ header, formModelId }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${MODEL}/${formModelId}/${ANSWERS}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFormModelVersionById = async ({
  header,
  formModelVersionId,
}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${MODEL}/${VERSION}/${formModelVersionId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFormById = async ({ header, formId }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${formId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFormAnswerPatientById = async ({ header, formId }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${formId}/${ANSWER}/${PATIENT}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFormPrintFormat = async ({ header, formId }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${formId}/${PRINT_FORMAT}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getAttendancePrintFormat = async ({ header, medicalRecordsAttendanceId }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${medicalRecordsAttendanceId}/${ATTENDANCE}/${PRINT_FORMAT}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFormModelByIdAnswer = async ({ header, formModelId }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${MODEL}/${formModelId}/${ANSWERS}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFormListAnswers = async ({ header }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getDrafts = async ({ header, isAccountForm = 0 }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${MODEL}/${DRAFT}/?isAccountForm=${isAccountForm}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getDraftById = async ({ header, draftId }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${MODEL}/${DRAFT}/${draftId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const checkNameFormModel = async ({ header, nameForm }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${MODEL}/${NAME}/${AVAILABILITY}/${nameForm}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const checkNameAccountFormModel = async ({ header, nameForm }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${MODEL}/${NAME}/${ACCOUNT}/${AVAILABILITY}/${nameForm}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion

//#region POST
export const createFormEmpty = async ({ header, formModelVersionId }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${EMPTY}/${formModelVersionId}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl);
};

export const createFormEmptyAttendanceAnswer = async ({ header, formModelVersionId, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${EMPTY}/${PATIENT}/${ANSWER}/${formModelVersionId}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const saveForm = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${MODEL}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const createAnsweredForm = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postDraft = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${MODEL}/${DRAFT}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion
let updateAnsweredFormPreviousCall = null; // keeps the last call out of the updateAnsweredForm scope
//#region PUT
export const updateAnsweredForm = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}`;
  const axios = interceptor(header);
  const source = axiosRef.CancelToken.source();
  const config = {
    cancelRequest: source.cancel,
    cancelToken: source.token,
  };
  if (updateAnsweredFormPreviousCall) updateAnsweredFormPreviousCall.cancel(); // cancels previous updateAnsweredForm reqs promises if they're not resolved
  updateAnsweredFormPreviousCall = source;

  return await patch(axios, pathUrl, params, config);
};

export const updateAnsweredFormPatient = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${ANSWER}/${PATIENT}`;
  const axios = interceptor(header);
  const source = axiosRef.CancelToken.source();
  const config = {
    cancelRequest: source.cancel,
    cancelToken: source.token,
  };
  if (updateAnsweredFormPreviousCall) updateAnsweredFormPreviousCall.cancel(); // cancels previous updateAnsweredForm reqs promises if they're not resolved
  updateAnsweredFormPreviousCall = source;

  return await patch(axios, pathUrl, params, config);
};

export const putDraft = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${MODEL}/${DRAFT}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};
//#endregion

export const deleteForm = async ({ header, formId }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, {formId});
}
